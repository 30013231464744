<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
        'login-confirmation-on': this.state == 'confirmation',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #fff4d0"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-30 pt-10">
          <div class="container">
            <div
              class="ml-lg-20 ml-md-20 text-lg-left text-md-left text-center"
            >
              <div
                class="container d-inline-block w-auto bg-white py-2 mb-5"
                style="border-radius: 24px"
              >
                <img
                  src="media/logos/main-logo.png"
                  class="max-h-35px"
                  alt=""
                />
              </div>
              <h3
                class="font-weight-bolder font-size-h6 font-size-h3-lg d-none d-md-block d-lg-block"
              >
                Selamat Datang di Grafindo Dokumen
              </h3>
              <p class="d-none d-md-block d-lg-block">
                Layanan Dokumen Karyawan Lingkungan PT Grafindo Media Pratama
              </p>
              <small class="text-muted d-none d-md-block d-lg-block"
                >Grafindo Assesment v{{ version }}</small
              >
            </div>
          </div>
        </div>
        <div class="d-none d-md-block d-lg-block">
          <div
            class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
            :style="{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: `75%`,
              backgroundPosition: `center`,
            }"
          ></div>
        </div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-10 pt-lg-0 pt-sm-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Masuk Akun
                </h3>
                <p>
                  Silakan lengkapi informasi Akun dibawah ini untuk melanjutkan
                  ke dalam sistem.
                </p>
                <div>
                  <span class="text-muted"
                    >Jika Anda belum melakukan aktivasi akun, silakan aktivasi
                    untuk mendapatkan akses ke berbagai fitur khusus dan
                    informasi penting dalam Grafindo Dokumen.
                    <a href="#" @click="showForm('signup')">Aktivasi</a>
                  </span>
                </div>
              </div>

              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Username</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.username"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Password</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    style="cursor: pointer"
                    >Lupa Password ?</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                  class="d-flex align-items-center"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-icon"
                      @click="showPassword = !showPassword"
                      type="button"
                    >
                      <i
                        class="fa"
                        :class="{
                          'fa-eye': !showPassword,
                          'fa-eye-slash': showPassword,
                        }"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Masuk
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signup_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Aktivasi Akun
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  masukkan data di bawah untuk melakukan aktivasi.
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="text"
                  placeholder="Masukkan NIK"
                  name="fullname"
                  ref="fullname"
                  autocomplete="off"
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  name="email"
                  ref="remail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
                <button
                  ref="kt_login_signup_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  style="width: 150px"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_signup_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Forgotten Password ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your email to reset your password
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  v-model="form.email"
                  name="email"
                  ref="femail"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="submit"
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Submit
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <!--        <div-->
        <!--          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"-->
        <!--        >-->
        <!--          <a href="#" class="text-primary font-weight-bolder font-size-h5"-->
        <!--            >Terms</a-->
        <!--          >-->
        <!--          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"-->
        <!--            >Plans</a-->
        <!--          >-->
        <!--          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"-->
        <!--            >Contact Us</a-->
        <!--          >-->
        <!--        </div>-->
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import packageJson from "../../../package.json";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  SIGN_UP,
  FORGOT,
} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      version: packageJson.version,
      state: "signin",
      showPassword: false,
      // Remove this dummy login info
      form: {
        // email: "admin@demo.com",
        // password: "demo"
        // username: "elhakim",
        // password: "banjaran0k"
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/logos/grafindo.svg";
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const signup_form = KTUtil.getById("kt_login_signup_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");
    // const confirmation_form = KTUtil.getById("login_confirmation_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv1 = formValidation(signup_form, {
      fields: {
        fullname: {
          validators: {
            notEmpty: {
              message: "Full name is required",
            },
          },
        },
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required",
            },
            emailAddress: {
              message: "The value is not a valid email address",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    // this.fv3 = formValidation(confirmation_form, {
    //   fields: {
    //     email: {
    //       validators: {
    //         notEmpty: {
    //           message: "Email is required",
    //         },
    //         emailAddress: {
    //           message: "The value is not a valid email address",
    //         },
    //       },
    //     },
    //   },
    //   plugins: {
    //     trigger: new Trigger(),
    //     submitButton: new SubmitButton(),
    //     bootstrap: new Bootstrap(),
    //   },
    // });

    this.fv.on("core.form.valid", () => {
      // var username = this.form.username;
      // var password = this.form.password;
      // clear existing errors
      this.$store.dispatch(LOGOUT);

      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(LOGIN, this.form)
        .then(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          Swal.fire({
            title: "Login Gagal",
            text: error.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
      // }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Tolong, berikan data yang benar!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    this.fv1.on("core.form.valid", () => {
      const email = this.$refs.remail.value;
      const username = this.$refs.fullname.value;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send register request
        this.$store
          .dispatch(SIGN_UP, {
            email: email,
            username: username,
          })
          .then((res) => {
            Swal.fire({
              title: "Berhasil dikirim ke email anda",
              text: "Silahkan periksa email anda untuk mengubah password",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.form = {};
                this.showForm("signin");
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Gagal Daftar",
              text: error.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }, 2000);
    });

    this.fv2.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    this.fv2.on("core.form.valid", () => {
      const email = this.$refs.femail;

      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(FORGOT, this.form)
        .then(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire({
            title: "Berhasil dikirim ke email anda",
            text: "Silahkan periksa email anda untuk mengubah password",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.form = {};
              this.showForm("signin");
            }
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Gagal Mengirim Email",
            text: error.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });

    // email
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";

      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
  },
};
</script>
